import React from "react"

import HeroSection from "./PageComponents/HeroSection"
import ImageByContent from "./PageComponents/ImageByContent"
import ImageByContentTwo from "./PageComponents/ImageByContentTwo"
import ContentByImage from "./PageComponents/ContentByImage"
import ThreeColumns from "./PageComponents/ThreeColumns"
import ColourBlock from "./PageComponents/ColourBlock"
import JustTitle from "./PageComponents/JustTitle"
import LogoCallout from "./PageComponents/LogoCallout"
import WysiwygByImage from "./PageComponents/WysiwygByImage"
import ExternalLink from "./PageComponents/ExternalLink"
import CircleImgByWysiwyg from "./PageComponents/CircleImgByWysiwyg"
import ColourBlockTwo from "./PageComponents/ColourBlockTwo"
import ContactForm from "./PageComponents/ContactForm"
import WysiwygEditor from "./PageComponents/WysiwygEditor"

const PageComponentGroups = props => {
  const { components } = props
  const allPageComponents =
    components?.acfMainTemplateFields?.pageComponents?.length > 0 ? (
      <>
        {components?.acfMainTemplateFields?.pageComponents.map(
          (component, index) => {
            switch (component?.fieldGroupName) {
              case "page_Acfmaintemplatefields_PageComponents_HeroSection":
                return <HeroSection key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ImageByContentBlock":
                return <ImageByContent key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ImageByContentBlockStyleTwo":
                return <ImageByContentTwo key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ContentByImageBlock":
                return <ContentByImage key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ThreeColumnsContent":
                return <ThreeColumns key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ColourBlock":
                return <ColourBlock key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_JustATitle":
                return <JustTitle key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_LogoCallout":
                return <LogoCallout key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_WysiwygByImagesSection":
                return <WysiwygByImage key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ExternalLink":
                return <ExternalLink key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_CircleImageByWysiwyg":
                return <CircleImgByWysiwyg key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ColourBlockVersionTwo":
                return <ColourBlockTwo key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_ContactFormSection":
                return <ContactForm key={index} data={component} />
              case "page_Acfmaintemplatefields_PageComponents_WysiwygEditor":
                return <WysiwygEditor key={index} data={component} />
              default:
                return (
                  <p>Cannot find this component {component.fieldGroupName}</p>
                )
            }
          }
        )}
      </>
    ) : (
      <p>This page has no content</p>
    )

  return <>{allPageComponents}</>
}

export default PageComponentGroups
