import React from "react"
import styled from "styled-components"
import BGImg from "gatsby-background-image"
import { H2White, H1Blue } from "../../styles/helpers"

const HeroSection = ({ data }) => {
  const heroImage = data.image.localFile.childImageSharp.fluid
  return (
    <HeroSectionStyled>
      <div className="heroContent">
        <div className="heroContent__wrapper">
          <div className="heroTitle">
            <h2>{data.title}</h2>
          </div>
          <div
            className="heroSubTitle"
            dangerouslySetInnerHTML={{ __html: data.content }}
          />
        </div>
      </div>
      <div className="heroImage">
        <BGImg tag="div" fluid={heroImage} />
      </div>
    </HeroSectionStyled>
  )
}

const HeroSectionStyled = styled.div`
  position: relative;
  width: 100%;
  height: 50rem;

  @media (min-width: 1025px) {
    height: 65rem;
  }

  .heroContent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99999;

    &__wrapper {
      width: 100%;
      max-width: 90rem;
      margin: auto;
      padding: 4rem 2rem;
    }

    .heroTitle {
      width: 100%;
      text-align: center;

      h2 {
        ${H1Blue};
        margin-bottom: 10rem;

        @media (min-width: 768px) {
          margin-bottom: 15rem;
        }

        @media (min-width: 1025px) {
          margin-bottom: 20rem;
        }
      }
    }

    .heroSubTitle {
      width: 100%;
      p {
        ${H2White};
        display: inline-block;
        padding: 0.25rem 1rem;
        background-color: rgba(0, 50, 96, 0.8);
      }
    }
  }

  .heroImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
`

export default HeroSection
