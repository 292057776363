import React from "react"
import styled from "styled-components"
import {
  H1Blue,
  HCalloutGreen,
  standardWrapper,
  fontSizer,
} from "../../styles/helpers"

const JustTitle = ({ data }) => {
  return (
    <JustTitleStyled>
      <div className="wrapper">
        <h1>{data.title}</h1>
        {data.addAQuote && (
          <div className="quote">
            <div dangerouslySetInnerHTML={{ __html: data.quote }} />
          </div>
        )}
      </div>
    </JustTitleStyled>
  )
}

const JustTitleStyled = styled.section`
  margin-top: 2.5rem;
  margin-bottom: 0;

  @media (min-width: 768px) {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  @media (min-width: 1025px) {
    margin-top: 6rem;
    margin-bottom: 2.5rem;
  }

  .wrapper {
    ${standardWrapper};
    text-align: center;
  }

  h1 {
    ${H1Blue};
    width: 100%;
  }

  .quote {
    width: 100%;
    margin-top: 5rem;

    p {
      ${HCalloutGreen};
      ${fontSizer(2.8, 4, 76.8, 160, 2.2)};
    }
  }
`

export default JustTitle
