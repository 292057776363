import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  B1White,
  Btn1Gold,
  colors,
  H2White,
  standardWrapper,
} from "../../styles/helpers"

import Leaves from "../Images/Leaves"

const ColourBlock = ({ data }) => {
  return (
    <ColourBlockStyled>
      <div className="wrapper">
        <div className="title">
          <h2>{data.title}</h2>
          <div>
            <Link to={data.buttonSlug}>{data.buttonText}</Link>
          </div>
        </div>
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: data.content }}
        />
      </div>
      <div className="leavesGraphic">
        <Leaves />
      </div>
    </ColourBlockStyled>
  )
}

const ColourBlockStyled = styled.section`
  position: relative;
  background-color: ${colors.colorSecondary};
  padding: 5rem 0;
  margin-top: 10rem;
  margin-bottom: 0.5rem;

  @media (min-width: 768px) {
    margin-top: 7.5rem;
  }

  @media (min-width: 1025px) {
    margin-top: 10rem;
  }

  .wrapper {
    ${standardWrapper};
  }

  .title {
    width: 100%;
    margin-bottom: 5rem;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      text-align: right;
      margin-right: 4rem;
      margin-bottom: 0;
    }

    h2 {
      ${H2White};
    }

    a {
      ${Btn1Gold};
    }
  }

  .content {
    width: 100%;
    text-align: center;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-left: 4rem;
      text-align: left;
    }

    p {
      ${B1White};
    }
  }

  .leavesGraphic {
    position: absolute;
    top: -10rem;
    left: -5rem;
    width: 25rem;
  }
`

export default ColourBlock
