import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Black,
  Btn1Gold,
  H1Blue,
  medWrapper,
  H2Green,
} from "../../styles/helpers"
import axios from "axios"

import Key from "../Images/Key"
import Leaves from "../Images/Leaves"

import Input from "../FormParts/Input"
import Textarea from "../FormParts/Textarea"
import CheckBoxInput from "../FormParts/CheckBoxInput"

import FormSuccess from "../FormParts/formModals/FormSuccess"
import FormSubmit from "../FormParts/formModals/FormSubmit"
import FormErrors from "../FormParts/formModals/FormErrors"

const ContactForm = ({ data }) => {
  const { formFields } = data

  const [formData, setFormData] = useState({
    hear: [],
    helpWith: [],
  })
  const [formStatus, setFormStatus] = useState({
    submitting: false,
    errorWarnDisplay: false,
    success: false,
    errors: [],
  })

  const submitToWebServer = async (formID, data) => {
    const FORM_POST_URL = `https://lochsmith.swbcreative.ca/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback`
    const config = { headers: { "Content-Type": "multipart/form-data" } }
    const serverResponse = await axios.post(FORM_POST_URL, data, config)

    if (serverResponse.data.status === "mail_sent") {
      return { errors: false, errorMessages: [] }
    } else {
      return { errors: true, errorMessages: serverResponse.data.invalid_fields }
    }
  }

  const handleFormSubmit = async e => {
    e.preventDefault()
    setFormStatus({
      ...formStatus,
      submitting: true,
    })
    const formDataArray = Object.entries(formData)
    const bodyFormData = new FormData()
    formDataArray.forEach(field => {
      bodyFormData.append(field[0], field[1])
    })
    const response = await submitToWebServer(8, bodyFormData)
    handleUpdateServerResponse(response)
  }

  const handleUpdateServerResponse = response => {
    if (!response.errors) {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: false,
        success: true,
        errors: [],
      })
    } else {
      setFormStatus({
        ...formStatus,
        submitting: false,
        errorWarnDisplay: true,
        success: false,
        errors: response.errorMessages,
      })
    }
  }

  useEffect(() => {
    const startingState = {}
    data.formFields.forEach(field => {
      if (field.inputType !== "checkbox") {
        return (startingState[field.id] = "")
      }
    })
    setFormData({ ...formData, ...startingState })
  }, [])

  const imageTop = getImage(
    data.imageTop.localFile.childImageSharp.gatsbyImageData
  )
  const imageTopAlt = data.imageTop.altText
  const imageBottom = getImage(
    data.imageBottom.localFile.childImageSharp.gatsbyImageData
  )
  const imageBottomAlt = data.imageBottom.altText

  const handleOnHelpChange = event => {
    const checkValue = event.target.value
    const copyOfChecked = formData.helpWith
    const checkedIndex = formData.helpWith.indexOf(checkValue)

    if (checkedIndex !== -1) {
      copyOfChecked.splice(checkedIndex, 1)
      setFormData({
        ...formData,
        helpWith: copyOfChecked,
      })
    } else {
      copyOfChecked.push(checkValue)
      setFormData({
        ...formData,
        helpWith: copyOfChecked,
      })
    }
  }

  const checkIfHelpChecked = name => {
    const checked =
      formData.helpWith.find(day => day === name) === undefined ? false : true
    return checked
  }

  const handleOnHearChange = event => {
    const checkValue = event.target.value
    const copyOfChecked = formData.hear
    const checkedIndex = formData.hear.indexOf(checkValue)

    if (checkedIndex !== -1) {
      copyOfChecked.splice(checkedIndex, 1)
      setFormData({
        ...formData,
        hear: copyOfChecked,
      })
    } else {
      copyOfChecked.push(checkValue)
      setFormData({
        ...formData,
        hear: copyOfChecked,
      })
    }
  }

  const checkIfHearChecked = name => {
    const checked =
      formData.hear.find(day => {
        return day === name
      }) === undefined
        ? false
        : true
    return checked
  }

  const handleOnChange = event => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    })
  }

  const handleErrorModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
    })
  }

  const handleSuccessModalClose = () => {
    setFormStatus({
      ...formStatus,
      submitting: false,
      errorWarnDisplay: false,
      success: false,
      errors: [],
    })
    setFormData({
      hear: [],
      helpWith: [],
    })
  }

  return (
    <ContactFormStyled>
      <div className="wrapper">
        <div className="images">
          <div>
            <GatsbyImage image={imageTop} alt={imageTopAlt} layout="fixed" />
          </div>
          <div className="images__bottom">
            <GatsbyImage
              image={imageBottom}
              alt={imageBottomAlt}
              layout="fixed"
            />
            <div className="images__graphic">
              <Leaves />
            </div>
          </div>
          {data.testimonialRequired && (
            <div className="images__testimoinal">
              <div dangerouslySetInnerHTML={{ __html: data.testimoinal }} />
            </div>
          )}
        </div>
        <div className="content">
          <div className="content__title">
            <h1>{data.title}</h1>
          </div>
          <div className="content__para">
            <div dangerouslySetInnerHTML={{ __html: data.content }} />
          </div>
          <div className="content__key">
            <div className="content__key--inner">
              <Key />
            </div>
          </div>
          <div className="content__form">
            <form onSubmit={e => handleFormSubmit(e)}>
              <fieldset>
                {formFields.map(field => {
                  const {
                    id,
                    inputType,
                    label,
                    placeholder,
                    required,
                    size,
                    options,
                  } = field
                  const errorMessage = formStatus.errors.find(
                    error => error.idref === id
                  )

                  let formField
                  if (inputType === "text" || inputType === "email") {
                    formField = (
                      <Input
                        key={id}
                        label={label}
                        name={id}
                        type={inputType}
                        placeholder={placeholder}
                        value={formData[id] ? formData[id] : ""}
                        onChange={handleOnChange}
                        fieldvalid={true}
                        size={size}
                        required={required}
                        error={errorMessage ? errorMessage.message : ""}
                      />
                    )
                  } else if (inputType === "textarea") {
                    formField = (
                      <Textarea
                        key={id}
                        label={label}
                        name={id}
                        placeholder={placeholder}
                        value={formData[id] ? formData[id] : ""}
                        onChange={handleOnChange}
                        size={size}
                        required={required}
                        error={errorMessage ? errorMessage.message : ""}
                      />
                    )
                  } else if (inputType === "checkbox" && id === "helpWith") {
                    formField = (
                      <CheckBoxInput
                        name={id}
                        label={label}
                        options={options}
                        onChange={handleOnHelpChange}
                        checked={checkIfHelpChecked}
                      />
                    )
                  } else if (inputType === "checkbox" && id === "hear") {
                    formField = (
                      <CheckBoxInput
                        name={id}
                        label={label}
                        options={options}
                        onChange={handleOnHearChange}
                        checked={checkIfHearChecked}
                      />
                    )
                  }
                  return formField
                })}
                <div className="submitButton">
                  <button type="submit">Submit</button>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <FormSubmit isActive={formStatus.submitting} />
      <FormSuccess
        isActive={formStatus.success}
        handleClose={handleSuccessModalClose}
      />
      <FormErrors
        isActive={formStatus.errorWarnDisplay}
        handleClose={handleErrorModalClose}
      />
    </ContactFormStyled>
  )
}

const ContactFormStyled = styled.div`
  .wrapper {
    ${medWrapper};
    padding-top: 0;
  }

  .images {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(33.333333%);
    }

    @media (min-width: 1025px) {
      width: calc(33.333333%);
      margin-top: 17.5rem;
    }

    &__bottom {
      position: relative;
      margin-top: 4rem;
    }

    &__graphic {
      position: absolute;
      left: 0%;
      bottom: -10rem;
      width: 25rem;
      transform: translateX(-50%);
      z-index: 500;

      @media (min-width: 768px) {
        left: 50%;
      }
    }

    &__testimoinal {
      width: 100%;
      margin-top: 7.5rem;

      @media (min-width: 768px) {
        margin-top: 10rem;
      }

      @media (min-width: 1025px) {
        margin-top: 12.5rem;
      }

      p {
        ${B1Black};
      }
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(66.666666% - 5rem);
      margin-left: 5rem;
    }

    &__title {
      margin-top: 10rem;

      @media (min-width: 768px) {
        margin-top: 5rem;
      }

      @media (min-width: 1025px) {
        margin-top: 10rem;
      }

      h1 {
        ${H1Blue};
      }
    }

    &__para {
      p {
        ${B1Black};
      }
    }

    &__key {
      width: 100%;

      &--inner {
        max-width: 15rem;
      }
    }

    &__form {
      width: 100%;

      fieldset {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        justify-content: space-evenly;
        border: none;
        outline: none;
        width: 100%;
      }

      .submitButton {
        width: 100%;
        padding-top: 3rem;
        padding-left: 0.5rem;

        button {
          ${Btn1Gold};
        }
      }
    }
  }
`

export default ContactForm
