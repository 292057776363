import React from "react"
import styled from "styled-components"
import { Btn1Gold, H3Gold, standardWrapper } from "../../styles/helpers"

const ExternalLink = ({ data }) => {
  return (
    <ExternalLinkStyled>
      <div className="wrapper">
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        <div className="link">
          <a rel="noreferrer" target="_blank" href={data.link}>
            {data.buttonText}
          </a>
        </div>
      </div>
    </ExternalLinkStyled>
  )
}

const ExternalLinkStyled = styled.div`
  padding: 2rem 0;

  .wrapper {
    ${standardWrapper};
    align-items: center;
  }

  .content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
      text-align: right;
    }

    p {
      ${H3Gold};
      margin: 0;
    }
  }

  .link {
    width: calc(100%);
    margin-top: 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-top: 0;
      margin-left: 4rem;
    }

    a {
      ${Btn1Gold};
    }
  }
`

export default ExternalLink
