import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { fontSizer, HCalloutGreen, standardWrapper } from "../../styles/helpers"

const LogoCallout = ({ data }) => {
  const image = getImage(data.logo.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = data.logo.altText
  return (
    <LogoCalloutStyled>
      <div className="wrapper">
        <div className="logo">
          <GatsbyImage image={image} alt={imageAlt} layout="fixed" />
        </div>
        <div className="callout">
          <h2>{data.callout}</h2>
        </div>
      </div>
    </LogoCalloutStyled>
  )
}

const LogoCalloutStyled = styled.div`
  padding: 0;
  @media (min-width: 768px) {
    padding: 5rem 0;
  }
  .wrapper {
    ${standardWrapper};
    align-items: center;
  }

  .logo {
    width: 100%;
    max-width: 20rem;
    margin: 0 auto 2rem;

    @media (min-width: 768px) {
      width: calc(25% - 10rem);
      max-width: 100%;
      margin: auto 5rem;
    }
  }

  .callout {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(75%);
    }

    h2 {
      ${HCalloutGreen};
      ${fontSizer(3, 4, 76.8, 160, 2.2)};
    }
  }
`

export default LogoCallout
