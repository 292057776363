import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  B1Black,
  colors,
  H1Blue,
  H2Green,
  H3Gold,
  standardWrapper,
} from "../../styles/helpers"
import { Link } from "gatsby"

const ThreeColumns = ({ data }) => {
  return (
    <ThreeColumnsStyled>
      <div className="wrapper">
        <div className="mainTitle">
          <h2>{data.title}</h2>
        </div>
        <div className="colWrapper">
          {data.step.map((item, index) => {
            const image = getImage(
              item.image.localFile.childImageSharp.gatsbyImageData
            )
            return (
              <ColumnStep key={index}>
                <div className="stepImage">
                  <GatsbyImage image={image} alt={item.title} layout="fixed" />
                </div>
                <div className="stepTitle">
                  <h3>{item.title}</h3>
                </div>
                <div
                  className="stepContent"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                />
                {item.buttonRequired && (
                  <div className="stepButton">
                    <Link to={item.buttonSlug}>{item.buttonText}</Link>
                  </div>
                )}
              </ColumnStep>
            )
          })}
        </div>
      </div>
    </ThreeColumnsStyled>
  )
}

const ThreeColumnsStyled = styled.section`
  .wrapper {
    ${standardWrapper};
  }

  .mainTitle {
    width: 100%;
    text-align: center;

    h2 {
      ${H1Blue};
    }
  }

  .colWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;
  }
`

const ColumnStep = styled.div`
  width: 100%;

  @media (min-width: 768px) {
    width: calc(33.33333333% - 4rem);
    margin: 1rem 2rem;
  }

  .stepImage {
    border-radius: 50%;
    overflow: hidden;
    margin: 1rem 3rem;
  }

  .stepButton {
    margin-top: 2.5rem;
  }

  h3 {
    ${H2Green};
    text-align: center;
  }

  p {
    ${B1Black};
  }

  a {
    ${H3Gold};
    transition: all 0.3s ease;

    &:hover {
      color: ${colors.colorPrimary};
    }
  }
`

export default ThreeColumns
