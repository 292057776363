import React from "react"
import styled from "styled-components"
import SimpleWysiwyg from "./SimpleWysiwyg"

const WysiwygEditor = ({ data }) => {
  return (
    <WysiwygEditorStyled>
      <SimpleWysiwyg data={data} />
    </WysiwygEditorStyled>
  )
}

const WysiwygEditorStyled = styled.div`
  width: 100%;
`

export default WysiwygEditor
