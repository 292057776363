import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { B1Black, medWrapper, colors, H2White } from "../../styles/helpers"

import Leaves from "../Images/Leaves"

const ContentByImage = ({ data }) => {
  const image = getImage(data.image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = data.image.altText
  return (
    <ContentByImageStyled>
      <div className="wrapper">
        <div className="content">
          <div>
            <h2>{data.title}</h2>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
        <div className="image">
          <GatsbyImage image={image} alt={imageAlt} layout="fixed" />
          <div className="image__graphic" />
          <div className="image__leaves">
            <Leaves />
          </div>
        </div>
      </div>
    </ContentByImageStyled>
  )
}

const ContentByImageStyled = styled.section`
  padding: 2rem 0;

  @media (min-width: 1025px) {
    padding: 5rem 0 7.5rem;
  }

  .wrapper {
    ${medWrapper};
  }

  .content {
    width: 100%;
    margin-bottom: 0;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(40% - 4rem);
      margin-right: 4rem;
    }

    p {
      ${B1Black};
      margin-bottom: 1.5rem;
    }

    h2 {
      ${H2White};
      padding: 1rem 2rem;
      background-color: ${colors.colorPrimary};
    }
  }

  .image {
    position: relative;
    width: 100%;
    margin-top: 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-top: 0;
      margin-left: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(60% - 4rem);
      margin-left: 4rem;
    }

    &__graphic {
      position: absolute;
      bottom: -2rem;
      left: -2rem;
      width: 100%;
      height: 100%;
      background-color: ${colors.colorSecondary};
      z-index: -1;
    }

    &__leaves {
      position: absolute;
      bottom: -9rem;
      left: -12rem;
      width: 25rem;
      z-index: 5;
    }
  }
`

export default ContentByImage
