import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  B1Black,
  Btn1Gold,
  HCalloutGreen,
  standardWrapper,
} from "../../styles/helpers"

const ImageByContent = ({ data }) => {
  const image = getImage(data.image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = data.image.altText

  return (
    <ImageByContentStyled>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage image={image} alt={imageAlt} layout="fixed" />
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
          <div>
            <h3>{data.calloutTitle}</h3>
          </div>
          {data.buttonRequired && (
            <div>
              <Link to={data.buttonSlug}>{data.buttonText}</Link>
            </div>
          )}
        </div>
      </div>
    </ImageByContentStyled>
  )
}

const ImageByContentStyled = styled.section`
  background-color: rgba(53, 130, 133, 0.15);
  padding: 2rem 0;

  @media (min-width: 1025px) {
    padding: 5rem 0 7.5rem;
  }

  .wrapper {
    ${standardWrapper};
  }

  .image {
    width: 100%;
    margin-bottom: 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
      margin-bottom: 0;
    }

    @media (min-width: 1025px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }
  }

  .content {
    width: 100%;
    margin-top: 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-top: 2.5rem;
      margin-left: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(50% - 4rem);
      margin-top: 5rem;
      margin-left: 4rem;
    }

    p {
      ${B1Black};
    }

    h3 {
      ${HCalloutGreen};
    }

    a {
      ${Btn1Gold};
    }
  }
`

export default ImageByContent
