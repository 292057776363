import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import {
  B1Black,
  Btn1Gold,
  HCalloutGreen,
  medWrapper,
  colors,
} from "../../styles/helpers"

const ImageByContentTwo = ({ data }) => {
  const image = getImage(data.image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = data.image.altText
  return (
    <ImageByContentTwoStyled>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage image={image} alt={imageAlt} layout="fixed" />
          <div className="image__graphic" />
        </div>
        <div className="content">
          <div>
            <h3>{data.calloutTitle}</h3>
          </div>
          <div dangerouslySetInnerHTML={{ __html: data.content }} />

          {data.buttonRequired && (
            <div>
              <Link to={data.buttonSlug}>{data.buttonText}</Link>
            </div>
          )}
        </div>
      </div>
    </ImageByContentTwoStyled>
  )
}

const ImageByContentTwoStyled = styled.section`
  .wrapper {
    ${medWrapper};
    align-items: flex-start;
  }

  .image {
    position: relative;
    width: 100%;
    margin-bottom: 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
      margin-bottom: 0;
    }

    @media (min-width: 1025px) {
      width: calc(60% - 4rem);
      margin-right: 4rem;
    }

    &__graphic {
      position: absolute;
      bottom: -2rem;
      right: -2rem;
      width: 100%;
      height: 100%;
      background-color: ${colors.colorSecondary};
      z-index: -1;
    }
  }

  .content {
    width: 100%;
    margin-top: 2.5rem;

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-top: 0;
      margin-left: 4rem;
    }

    @media (min-width: 1025px) {
      width: calc(40% - 4rem);
      margin-left: 4rem;
    }

    p {
      ${B1Black};
    }

    h3 {
      ${HCalloutGreen};
    }

    a {
      ${Btn1Gold};
    }
  }
`

export default ImageByContentTwo
