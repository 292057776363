import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { H2Green, medWrapper } from "../../styles/helpers"
import SimpleWysiwyg from "./SimpleWysiwyg"

const CircleImgByWysiwyg = ({ data }) => {
  const image = getImage(data.image.localFile.childImageSharp.gatsbyImageData)
  const imageAlt = data.image.altText
  return (
    <CircleImgByWysiwygStyled id={data.sectionId}>
      <div className="wrapper">
        <div className="image">
          <GatsbyImage image={image} alt={imageAlt} layout="fixed" />
        </div>
        <div className="content">
          <div className="content__title">
            <h2>{data.mainTitle}</h2>
          </div>
          <SimpleWysiwyg data={data} />
        </div>
      </div>
    </CircleImgByWysiwygStyled>
  )
}

const CircleImgByWysiwygStyled = styled.section`
  margin: 2rem auto;

  .wrapper {
    ${medWrapper};
  }

  .image {
    width: 100%;
    max-width: 25rem;
    margin-right: auto;
    margin-bottom: 2.5rem;
    margin-left: auto;

    @media (min-width: 768px) {
      width: calc(25% - 2rem);
      max-width: 100%;
      margin-right: 2rem;
      margin-bottom: 0;
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(75% - 2rem);
      margin-left: 2rem;
    }

    &__title {
      h2 {
        ${H2Green};
        margin: 0;
        padding-left: 2rem;
      }
    }
  }
`

export default CircleImgByWysiwyg
