import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { medWrapper } from "../../styles/helpers"

import SimpleWysiwyg from "./SimpleWysiwyg"

const WysiwygByImage = ({ data }) => {
  const mainImage = getImage(
    data.mainImage.localFile.childImageSharp.gatsbyImageData
  )

  const mainImageAlt = data.mainImage.altText
  return (
    <WysiwygByImageStyled>
      <div className="wrapper">
        <div className="content">
          <SimpleWysiwyg data={{ wysiwygContent: data.wysiwyg }} />
        </div>
        <div className="images">
          <div className="images__main">
            <GatsbyImage image={mainImage} alt={mainImageAlt} layout="fixed" />
          </div>
          <div className="images__gallery">
            {data.gallery.map((image, index) => {
              const GalImage = getImage(
                image.localFile.childImageSharp.gatsbyImageData
              )
              return (
                <div className="galleryImage" key={index}>
                  <GatsbyImage image={GalImage} alt="" layout="fixed" />
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </WysiwygByImageStyled>
  )
}

const WysiwygByImageStyled = styled.div`
  .wrapper {
    ${medWrapper};
  }

  .content {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-right: 4rem;
    }
  }

  .images {
    width: calc(100%);

    @media (min-width: 768px) {
      width: calc(50% - 4rem);
      margin-left: 4rem;
    }

    &__gallery {
      display: flex;
      flex-wrap: nowrap;
      justify-content: center;
      width: 100%;

      .galleryImage {
        margin-top: 1rem;
      }

      .galleryImage:nth-of-type(1) {
        margin-right: 0.75rem;
      }

      .galleryImage:nth-of-type(2) {
        margin-right: 0.75rem;
        margin-left: 0.75rem;
      }
      .galleryImage:nth-of-type(3) {
        margin-left: 0.75rem;
      }
    }
  }
`

export default WysiwygByImage
