import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import {
  B1White,
  Btn1Blue,
  colors,
  H2White,
  standardWrapper,
} from "../../styles/helpers"

import Key from "../Images/Key"

const ColourBlockTwo = ({ data }) => {
  return (
    <ColourBlockTwoStyled>
      <div className="wrapper">
        <div className="keyGraphic">
          <Key />
        </div>
        <div className="title">
          <div>
            <h2>{data.title}</h2>
          </div>
          <div>
            <Link to={data.buttonSlug}>{data.buttonText}</Link>
          </div>
        </div>
        <div className="content">
          <div dangerouslySetInnerHTML={{ __html: data.content }} />
        </div>
      </div>
    </ColourBlockTwoStyled>
  )
}

const ColourBlockTwoStyled = styled.section`
  padding: 2.5rem 0 5rem;
  background-color: ${colors.colorTertiary};

  .wrapper {
    ${standardWrapper};
    align-items: flex-start;
  }

  .keyGraphic {
    width: calc(100% - 20rem);
    margin: auto 10rem 5rem;

    @media (min-width: 768px) {
      width: calc(20% - 5rem);
      margin: auto 5rem auto auto;
      margin-right: 5rem;
    }
  }

  .title {
    width: 100%;
    margin-bottom: 2.5rem;

    @media (min-width: 768px) {
      width: calc(20%);
      margin-bottom: 0;
      text-align: right;
    }

    h2 {
      ${H2White};
      margin-top: 0;
    }

    a {
      ${Btn1Blue};
    }
  }

  .content {
    width: 100%;

    @media (min-width: 768px) {
      width: calc(60% - 5rem);
      margin-left: 5rem;
    }

    p {
      ${B1White};

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

export default ColourBlockTwo
